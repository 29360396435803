// eslint-disable-line array-callback-return
import {useState, useEffect, useRef, useMemo } from 'react';
import Score from "./Score.js";
import CustomButton from './CustomButton.js';
import DislikeModal from './DislikeModal.js'
import posthog from 'posthog-js';
import IframeModal from './IframeModal.js';

import { likeshort, unlikeshort, getRequest } from '../../api/shorts-api.js';
import { toast } from 'react-toastify';
import faDownload from '../../assets/DownloadBlue.svg'
import faEdit from '../../assets/download.svg'
import ThumbsDown from '../../assets/ThumbsDown.svg'
import ThumbsUp from '../../assets/ThumbsUp.svg'
import ThumbsUpblue from '../../assets/ThumbsUp-blue.svg'
import ShareNetwork from '../../assets/ShareNetwork.svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

// import DislikeModal from './DislikeModal';



const Short = (
  {
    userId,
    requestUserEmail,
    item,
    index,
    ShortsMakerField,
    requestId,
    isCurrentUserRequest,
    isValidDomain,
    youTubeURL,
    shortsCreatorURL,
    paragraph
  }
) => {
  console.log("item: ", item.id);
  console.log("item: ", item);
  console.log("shortsCreatorURL: ", shortsCreatorURL);
  console.log("ShortsMakerField on single short", ShortsMakerField);

  const Link = ({ id, children, title }) => (
    <OverlayTrigger placement="bottom" overlay={<Tooltip style={{ fontSize: '14px' }} id={id}>{title}</Tooltip>}>
      {children}
    </OverlayTrigger>
  );

  const requestUserInfo = {
    'userId': userId,
    'requestUserEmail': requestUserEmail,
    'item': item,
    'requestId': requestId
  }
  // const [isrecompile, setisrecompile] = useState(item?.recompiling);
  const [localItem, setLocalItem] = useState(item);
  const [isrecompile, setisrecompile] = useState(localItem?.recompiling ?? false);
  // const [isrecompile, setisrecompile] = useState(true);
  const [isrecompileError, setisrecompileError] = useState(localItem?.recompileFailed ?? false);
  // const [isrecompileError, setisrecompileError] = useState(true);
  const [isShortLiked, setIsShortLiked] = useState(localItem?.liked || false);
  const [isShortDisLiked, setisShortDisLiked] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [showIframeModal, setShowIframeModal] = useState(false);
  const shortcodeSettings = ShortsMakerField?.shortcodeSettings1;

  // Function to open the IframeModal
  const openIframeModal = () => {
   
    setShowIframeModal(true);
  };

  // Function to close the IframeModal
  const closeIframeModal = () => {
    setShowIframeModal(false);
  };

  useEffect(() => {
    let interval;
    const fetchStatus = async () => {
      try {
        const fetchedRequest = await getRequest(requestId);
        const isRecompiling = fetchedRequest?.shorts.some(short => short.id === localItem?.id && short?.recompiling === true);
        setisrecompile(isRecompiling);
        setisrecompileError(fetchedRequest?.shorts.some(short => short.id === localItem?.id && short?.recompileFailed === true));
        // setisrecompileError(true);

        if (!isRecompiling) {
          clearInterval(interval);
          const updatedItem = fetchedRequest?.shorts.find(short => short.id === localItem?.id);
          if (updatedItem) {
            setLocalItem(updatedItem);
          }
        }
      } catch (error) {
        clearInterval(interval);
      }
    };

    if (isrecompile) {
      interval = setInterval(fetchStatus, 6000);
    }

    return () => clearInterval(interval);
  }, [isrecompile, requestId, localItem?.id]);


  const [videoDuration, setVideoDuration] = useState(null);
  const videoRef = useRef(null);

  const handleVideoLoad = () => {
    if (videoRef.current) {
      console.log("Time sec", videoRef.current.duration);
      setVideoDuration(videoRef.current.duration);
    }
  };


  const UserStatusData = ShortsMakerField?.UserStatusData;
  const IsUserLoggedIn = UserStatusData?.isLoggedIn ? UserStatusData?.isLoggedIn : false;
  const reason = localItem?.reason;
  const viral_score = localItem?.viral_score;
  const clips = localItem?.clips;
  const shortsId = localItem?.id;
  const formatDuration = useMemo(() => (startTimestamp, endTimestamp) => {
    const durationInSeconds = Math.round(endTimestamp - startTimestamp);
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return hours > 0
      ? `${hours}:${formattedMinutes}:${formattedSeconds}`
      : `${formattedMinutes}:${formattedSeconds}`;
  }, []);

  const formatDurationMinToSec = useMemo(() => (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }, []);

  const handleEdit = () => {

    /**
    * - If user is not logged -> redirect to Login Page
    * - If user is logged in
    *   - If user is the owner of this request -> redirect to Editor (Positive flow)
    *   - If user is not the owner of the request -> show alert: You can only edit shorts you've created
    */

    // redirect to signin if user not logged in
    // if (typeof userId === 'undefined') {
    //   var currentUrl = window.location.href;
    //   var updatedUrl =
    //     currentUrl +
    //     (currentUrl.indexOf("?") !== -1 ? "&" : "?") +
    //     "shortId=" +
    //     shortsId;
    //   var encodedUrl = encodeURIComponent(updatedUrl);
    //   let signInRedirectURL = ShortsMakerField?.shortcodeSettings1?.auth_settings_options?.signin_url_1 + "?external=true&redirectTo=" + encodedUrl;
    //   console.log("Sign in --- Redirection url with short id", signInRedirectURL);
    //   window.location.href = signInRedirectURL;
    //   return;
    // }

    // // if Logged in but not the owner of request
    // if (typeof userId !== 'undefined' && !isCurrentUserRequest && !isValidDomain) {
    //   toast.error("You can only edit shorts you've created");
    //   return;
    // }

    const baseUrl = 'http://app.videopeel.ssemble.com';
    const updatedUrl1 = `${baseUrl}/shorts/${requestId}/${shortsId}`;
    // let sm_pluginsURL = ShortsMakerField?.shortcodeSettings1?.auth_settings_options?.signin_url_1 + "?pgnInstallId=ShortsMaker&install=true";
    // var channelLinkjson = { "requestId": requestId, "shortId": shortsId }
    // var encodedData = encodeURIComponent(
    //   JSON.stringify(channelLinkjson));
    // var updatedUrl = sm_pluginsURL + '&msg=' + encodedData;
    console.log("Redirection url with short id", updatedUrl1);
    const eventProperties1 = {
      page_title: document.title,
      page_url: window.location.href,
      RequestID: requestId,
      ShortID: shortsId,
      redirection_url: updatedUrl1
    };

    posthog.capture('Edit Short', eventProperties1);
    
    window.location.href = updatedUrl1;

  };
  const handleDownload = () => {

    const ReqVideoURL = localItem?.video_url;
    console.log("ReqVideoURL::", ReqVideoURL);

    const filename = localItem?.title.replace(/[^\p{L}\p{N}\s]/gu, '') + '.mp4';
    downloadVideo(ReqVideoURL, filename);

    // return;
    const eventProperties1 = {
      page_title: document.title,
      page_url: window.location.href,
      RequestID: requestId,
      ShortID: shortsId,
      video_url: localItem?.video_url,
      youtubeURL: youTubeURL,
    };

    

      let userEmail = null;
      if (IsUserLoggedIn) {
        userEmail = UserStatusData?.userEmail;
      }
      console.log("userEmail :: ", userEmail);
      // amplitude.getInstance().setUserId(userEmail);
      // amplitude
      //   .getInstance()
      //   .logEvent("Download Short", eventProperties1);
    

      posthog.capture("Download Short", eventProperties1);
    
  };

  const downloadVideo = (url, filename) => {
    console.log("fetching video blob");
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(error => {
        console.error('Error downloading video:', error);
      });

  };

  // Retrieve the offset_x value from the first clip.
  let offset_x = 0; // Set a default value of 0.
  if (clips && clips[0]) {
    offset_x = clips[0].offset_x;
    console.log('offset_x', offset_x);
  }

  // Calculate the scaleFactor by dividing the canvas width by the original image width.
  const scaleFactor = 258 / 1920;

  // Adjust the offset using the scaleFactor.
  const adjustedOffsetX = offset_x * scaleFactor;

  // Set the image style here. This adjusts the image horizontally.
  const imageStyle = {
    width: '100%', // Set to match the container width.
    height: '100%', // Set to match the container height.
    objectFit: 'cover', // Ensures the image maintains its aspect ratio and fills the container.
    objectPosition: `${adjustedOffsetX}px center` // Adjusts the horizontal position of the image.
  };


  /*
  Like / Dislike functionliaty
  */
  async function handleLike() {
    setIsShortLiked(true);
    const data = {
      "requestId": requestId,
      "shortId": shortsId
    };
    let response = await likeshort(data);
    console.log("likeshort response", response);

    if (response?.success) {
      setIsShortLiked(true);
    } else {
      setIsShortLiked(false);
    }
  };

  async function handleUnLike() {
    setIsShortLiked(false);
    const data = {
      "requestId": requestId,
      "shortId": shortsId
    };
    let response = await unlikeshort(data);
    console.log("likeshort response", response);

    if (response?.success) {
      setIsShortLiked(false);
    } else {
      setIsShortLiked(true);
    }
  };

  const handleDislike = () => {
    setModalShow(true);
  };

  const handleOnSuccess = (status) => {
    setModalShow(false);
    if (status) {
      toast.success("Thanks for submitting the feedback.");
      setisShortDisLiked(true);
    } else {
      toast.success("Something went wrong");
      setisShortDisLiked(false);
    }

  };

  const handleVideoPlay = () => {
    if (videoRef.current) {
      videoRef.current.webkitEnterFullscreen = null;
      videoRef.current.webkitExitFullscreen = null;
    }
  };

  return (
    <div className="SingleShortWrapper">
      <div className='d-none'>{localItem?.id}</div>
      <h2>{localItem?.title}</h2>
      <div className="short shirt-new">
        <div className={`short_card_footer ${isrecompile ? "ssDisabled" : ""}`}>
          <div className={`like-dislike-group`}>
            <div className="commonWraplikedislike likeWrap">
              <img src={isShortLiked ? ThumbsUpblue : ThumbsUp} alt="Like" onClick={isShortLiked ? handleUnLike : handleLike} className={isShortLiked ? 'alreadyLiked' : 'notalreadyLiked'} />
            </div>
            <div className="commonWraplikedislike dislikeWrap">
              {!isShortDisLiked && (
                <>
                  <img src={ThumbsDown} alt="Dislike" onClick={handleDislike} />
                  {
                    modalShow && (
                      <DislikeModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        onSuccess={handleOnSuccess}
                        requestId={requestId}
                        shortsId={shortsId}
                      />
                    )
                  }

                </>
              )}

            </div>

          </div>
        </div>
        <div className={`short-left ${isrecompile ? "ssDisabled" : ""}`} >
          <div className={`videoWrapper_custom ${isrecompile || isrecompileError ? "ssDisabled" : ""}`}>
            {
              isrecompile && (
                <div className='isCompilingLabel'>Compiling</div>
              )
            }
            {
              !isrecompile && isrecompileError && (
                <div className='isCompilingLabel'>Error</div>
              )
            }
            <div className='ssContentOnly'>
              <div
                className="shortClipTime"
              >
                {/* {formatDuration(localItem?.startTimestamp, localItem?.endTimestamp)} */}
                {/* <span className='time-label'>LOW-RES PREVIEW</span> */}
                <span className='time'>{formatDurationMinToSec(videoDuration)}</span>
              </div>
              {
                localItem?.video_url
                  ?
                  <div className="videoWrap">
                    <video
                      ref={videoRef}
                      key={isrecompile}
                      id="editVideo"
                      controls={isrecompile || isrecompileError ? "" : "controls"}
                      onLoadedMetadata={handleVideoLoad}
                      onPlay={handleVideoPlay}
                      playsInline
                    >
                      <source
                        src={localItem?.video_url}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  :
                  <div className="imageWrap" style={{ width: '145px', height: '258px', overflow: 'hidden' }}>
                    <img src={localItem?.thumbnail} alt={localItem?.title} style={imageStyle} />
                  </div>
              }
            </div>

          </div>

          <div className="button-group">
            <div className="commonWraplikedislike ShareWrap d-none">
              <div className='ss_share_btn_wrapper'>
                <Link title="Share" id="t-1">
                  <button className={`reels-preview-btn download-btn ss_share_btn ${isrecompile || isrecompileError ? "ssDisabled" : ""}`} onClick={openIframeModal}>
                    <img src={ShareNetwork} alt="Share" />
                    Share
                  </button>
                </Link>
                {showIframeModal && (
                  <IframeModal
                    show={showIframeModal}
                    onHide={closeIframeModal}
                    videoUrl={localItem?.video_url}
                    videoTitle={localItem?.title}
                    videoDescription={localItem?.description}
                    shortsCreatorURL={shortsCreatorURL}
                    ShortsMakerField={ShortsMakerField}
                    requestUserInfo={requestUserInfo}
                  />
                )}
              </div>
            </div>

            {/* <a
                    href={localItem?.video_url}
                    download="my_file"
                    title="Download"
                    disabled={!localItem?.video_url}
                  > */}
            <CustomButton title="Download" icon={faDownload} classListitem={`reels-preview-btn edit-btn ${isrecompile || isrecompileError ? "ssDisabled" : ""}`} text="Download" onClick={handleDownload}>
              Download
            </CustomButton>
            {/* </a> */}
            <CustomButton title="Edit" icon={faEdit} classListitem="reels-preview-btn edit-btn" text="Edit" onClick={handleEdit}>
              Edit
            </CustomButton>
          </div>
        </div>
        <div className="short-right">
          <Score viral_score={viral_score} />
          <div className="shorts-par-wrapper">
            <div>
              <p>
                {reason}
              </p>
            </div>
            <div>
              <p>
                {paragraph}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Short;